import Store from '../../store/store';

import { getDefaultMoment } from '../../utils/formatDate';

import { SORTING_EVENTS, STATUS_EVENTS } from '../../constants/company';
import { SERVICETYPE } from '../../constants/serviceType';
import { FIELDS } from '../../constants/trips';
import { PROVIDERS_NAMES } from '../../constants/insurance';

let ACTIONS;

const defaultCustomFile = [
  {
    uploaded: false,
    filed: false,
    file: null,
  },
];

export const LOADING_STATUS = {
  NEVER: 'NEVER',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

const defaultEventFile = [
  {
    uploaded: false,
    filed: false,
    file: null,
  },
];

const defaultBusFile = [
  {
    uploaded: false,
    filed: false,
    file: null,
  },
];

const DEFAULT_GIFT_VOUCHER = {
  isOpenGiftVoucher: false,
  isSecondStep: false,
  companyId: null,
  selectedEmployee: {},
  employeeInfo: {},
  startDate: getDefaultMoment(),
  endDate: getDefaultMoment(),
  classes: [],
  limit: 0,
  loadingSave: false,
  resultSave: null,
  validation: {
    selectedEmployee: '',
    startDate: '',
    endDate: '',
    classes: '',
    limit: '',
  },
};

const DEFAULTTRIP = {
  loading: true,
  aeroexpressLoading: false,
  ticketFileId: null,
  errorReqFile: false,
  mergeDialogLoading: LOADING_STATUS.NEVER,
  mergeTripsLoading: LOADING_STATUS.NEVER,
  tripToMerge: null,
  currentTrip: null,
  list: null,
  users: null,
  integration: false,
  integrationVersions: [{}],
  mandatoryProject: [],
  companies: [],
  projects: [],
  projectsForNewTrip: [],
  changeRate: {
    airline: {},
    train: {},
    bus: {},
  },
  versionButtons: [],
  employeesList: [],
  loadingEmployeesList: false,
  errorEmployeesList: '',
  operationAirline: '',
  aeroExpressRefundSum: {
    refundSum: 0,
    result: true,
    error: '',
  },
  airRefundSum: {
    totalRefundSum: 0,
    totalPenaltySum: 0,
    success: true,
    error: '',
    tickets: [],
  },
  penalties: {
    tripItemId: '',
    basePenalty: 0,
    fee: 0,
    commission: 0,
  },
  successRefund: true,
  aeroExpressFail: false,
  errorRefund: '',
  ticketStatus: '',
  tripComments: [],
  customFile: defaultCustomFile,
  eventFile: defaultEventFile,
  busFile: defaultBusFile,
  waitPreloader: false,
  isLegalDialog: false,
  legalEntity: {
    error: false,
    list: null,
  },
  legalAllowEditingValues: [],
  legalContractorIds: [],
  legalSelectValue: null,
  cancelVoucherDialog: {
    isRefundDialog: false,
    isAprooveDialog: false,
    isResultDialog: false,
    dialogResultMsg: '',
    amountCancelVoucher: 0,
    loadingCancelDialog: false,
    cancelVoucherInputValue: '',
  },
  giftVoucherDialog: DEFAULT_GIFT_VOUCHER,
  events: [],
  fullEvents: [],
  event: {
    label: 'отсутствует',
    value: '',
  },
  estimate: 0,
  insurance: {
    rates: [],
    items: [],
    companyId: 0,
    provider: PROVIDERS_NAMES.VSK,
    selectedItems: [],
    selectedRate: '',
    description: '',
    totalPrice: 0,
    orderTripItemId: 0,
    orderId: '',
    status: 0,
    employees: [],
    price: {
      Base: 0,
      Commission: 0,
    },
    validation: {
      Items: [],
      Rate: '',
      Base: '',
      Commission: '',
      CompanyId: '',
      Description: '',
      SelectedItems: '',
      SelectedRate: '',
    },
  },
  groupHotels: {
    ProviderInn: '',
    ReportAmount: 0,
    isNewTrip: true,
    contract: null,
    connected: null,
    compared: null,
    rates: [],
    showModal: false,
    showModalEditMainHotelFields: false,
    CustomHotelName: '',
    disableMainHotelFields: true,
    disableLocation: true,
    hotelName: {
      label: '',
      selected: null,
      suggests: [],
    },
    JsonData: {
      ReservationDate: null,
      ProviderName: '',
      Hotel: {
        Name: '',
        Address: '',
        Email: '',
        City: '',
        Location: '',
        Phone: '',
        RegionId: 0,
        Stars: 0,
        CheckinTime: '',
        CheckoutTime: '',
        ClassificatorId: 0,
      },
    },
    validation: {
      EmployeeFirst: '',
      ReservationNumber: '',
      ReservationDate: '',
      GuestCount: '',
      CheckinDate: '',
      CheckoutDate: '',
      ProviderInn: '',
      Hotel: {
        Name: '',
        Address: '',
        City: '',
        Location: '',
      },
      Base: '',
      commission: '',
      CompanyId: '',
      ProjectId: '',
      DepartmentId: '',
      HasVat: '',
      Total: '',
      From: '',
      Penalty: { Base: '' },
      Additional: '',
      BreakfastName: '',
      CurrencyInfo: {
        CurrencyMFInCurrency: '',
        CurrencyInclusive: '',
        CurrencyBase: '',
        CurrencyCommission: '',
      },
    },
  },
};

const STATUS = {
  0: 'Booked',
  1: '',
  2: 'AwaitingConfirmation',
  3: 'AwaitingTicketing',
};

const STATUS_RU = {
  Booked: 'Забронирован',
  AwaitingConfirmation: 'Ожидает подтверждения',
  Cancelled: 'Отменена',
  Completed: 'Завершена',
  AwaitingTicketing: 'Ожидает бронирования',
};

const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

const startEdit = (prevState, prevStatus) => {
  const state = { ...prevState };
  let status = prevStatus;

  state.cashTrip = cloneObj(state.currentTrip);

  if (typeof status === 'number') status = STATUS[status];

  state.currentTrip.Status = status;
  state.currentTrip.StatusRu = STATUS_RU[status];

  return state;
};

const cancelApplication = (prevState, value) => {
  const newState = { ...prevState };

  const eventIndex = prevState.fullEvents.findIndex((event) => event.Id === value);

  if (eventIndex !== -1) {
    newState.fullEvents[eventIndex].Status = STATUS_EVENTS.Cancelled;
  }

  return newState.fullEvents;
};

const deleteApplication = (prevState, value) => {
  const newState = { ...prevState };

  const eventIndex = prevState.fullEvents.findIndex((event) => event.Id === value);

  if (eventIndex !== -1) {
    newState.fullEvents.splice(eventIndex, 1);
  }

  return newState.fullEvents;
};

const sotringApplication = ({ fullEvents }, type) => {
  switch (type) {
    case SORTING_EVENTS.DATE:
      return fullEvents.sort((
        { CreatedDate: createdDateA },
        { CreatedDate: createdDateB },
      ) => new Date(createdDateB) - new Date(createdDateA));
    case SORTING_EVENTS.NAME:
      return fullEvents.sort(({ Name: nameA }, { Name: nameB }) => nameA.localeCompare(nameB, 'en', { numeric: true }));
    case SORTING_EVENTS.ID:
      return fullEvents.sort(({ NumericId: idA }, { NumericId: idB }) => idB - idA);
    case SORTING_EVENTS.TIME:
      return fullEvents.sort(({ CreatedDate: dateA }, { CreatedDate: dateB }) => {
        const dA = new Date(dateA);
        const dB = new Date(dateB);

        const minutesA = (dA.getHours() * 60) + dA.getMinutes();
        const minutesB = (dB.getHours() * 60) + dB.getMinutes();

        return minutesB - minutesA;
      });
    case SORTING_EVENTS.LOGIN:
      return fullEvents.sort(
        ({ User: loginA }, { User: loginB }) => loginA.localeCompare(loginB, 'en', { numeric: true }),
      );
    case SORTING_EVENTS.STATUS:
      return fullEvents.sort((
        { Status: statusA },
        { Status: statusB },
      ) => Object.values(STATUS_EVENTS).indexOf(statusA) - Object.values(STATUS_EVENTS).indexOf(statusB));
    default:
      return fullEvents;
  }
};

const cancelEdit = (prevState, prevStatus) => {
  const state = { ...prevState };
  let status = prevStatus;

  state.currentTrip = !state.cashTrip ? state.currentTrip : cloneObj(state.cashTrip);

  if (typeof status === 'number') status = STATUS[status];

  state.currentTrip.Status = status;
  state.currentTrip.StatusRu = STATUS_RU[status];

  return state;
};

const addHistoryItem = (state, item) => {
  if (state.currentTrip && state.currentTrip.OrderTripItems.length) {
    for (let i = 0, order = state.currentTrip.OrderTripItems[0];
      i < state.currentTrip.OrderTripItems.length; i++, order = state.currentTrip.OrderTripItems[i]) {
      if (order.Id === item.OrderTripItemId) {
        order.HistoryItems.push(item);
        break;
      }
    }
  }
};

const preparedVersionButtons = ({ OrderItems }) => {
  if (OrderItems.length === 0) return [];

  const buttons = [];

  OrderItems.forEach(({ Versions }) => Versions.forEach(({ Id, ServiceType }) => {
    if (ServiceType === SERVICETYPE.AIR) {
      buttons.push({
        id: Id,
        fullPrice: false,
      });
    }
  }));

  return buttons;
};

const prepareNewItemsData = (items, newData, ind) => items.map((item, index) => {
  if (ind === index) {
    return newData;
  }

  return item;
});

const reducer = (action, prevState) => {
  let state = { ...prevState };

  switch (action.type) {
    case ACTIONS.TRIP.UPDATE:
      state.currentTrip = action.trip;
      state.cashTrip = cloneObj(action.trip);
      state.currentTrip.StatusRu = STATUS_RU[state.currentTrip.Status];
      state.versionButtons = !state.currentTrip.CompanyId && state.currentTrip.AccountId && state.currentTrip.OrderItems
        ? preparedVersionButtons(state.currentTrip)
        : [];
      break;

    case ACTIONS.TRIP.LOADTRIPLIST:
      state.list = action.list;
      break;

    case ACTIONS.TRIP.STARTEDIT:
      state = startEdit(state, action.status);
      break;

    case ACTIONS.TRIP.CANCELEDIT:
      state = cancelEdit(state, action.status);
      break;

    case ACTIONS.TRIP.ADDHISTORYITEM:
      addHistoryItem(state, action.tripItem);
      break;

    case ACTIONS.TRIP.SET_ERROR_REQ_FILE:
      state.errorReqFile = action.payload;
      break;

    case ACTIONS.TRIP.UPDATELOADING:
      state.loading = action.payload;
      break;

    case ACTIONS.TRIP.UPDATE_DIALOG_LOADING:
      state.mergeDialogLoading = action.payload;
      break;

    case ACTIONS.TRIP.SET_MERGING_LOADING:
      state.mergeTripsLoading = action.payload;
      break;

    case ACTIONS.TRIP.SET_TRIP_TO_MERGE:
      state.tripToMerge = action.payload;
      break;

    case ACTIONS.TRIP.LOADCOMPANIES: {
      const companies = action.companies;

      state.companies = companies.length > 0
        ? companies.map(({
          CompanyId, ShortCompanyName, CompanyName,
        }) => ({
          id: CompanyId,
          name: ShortCompanyName || CompanyName,
        }))
        : companies;

      break;
    }

    case ACTIONS.TRIP.RESET_ERROR_AERO: {
      state.errorReqFile = false;
      break;
    }

    case ACTIONS.TRIP.SET_AERO_LOADING:
      state.aeroexpressLoading = action.payload;
      break;

    case ACTIONS.TRIP.GET_TICKET_FIELD_ID: {
      const { FileId } = action.payload;
      state.ticketFileId = FileId;

      break;
    }

    case ACTIONS.TRIP.LOADPROJECTS: {
      const projects = action.projects;

      state.projects = projects.length > 0
        ? projects.map(({
          Id, Name, IsArchival,
        }) => ({
          id: Id,
          name: Name,
          isArchival: IsArchival,
        }))
        : projects;

      state.projectsForNewTrip = state.projects.filter(((i) => !i.isArchival));

      break;
    }

    case ACTIONS.TRIP.LOADANALYTICS: {
      state.analytics = action.analytics;

      break;
    }

    case ACTIONS.TRIP.SET_WAIT_PRELOADER: {
      return {
        ...state,
        waitPreloader: action.payload,
      };
    }

    case ACTIONS.TRIP.SET_LEGAL_LIST: {
      const legalList = action.payload.map(({ Name, Id }) => ({
        label: Name,
        id: Id,
        value: Name,
      }));

      return {
        ...state,
        legalEntity: {
          error: false,
          list: legalList,
        },
      };
    }

    case ACTIONS.TRIP.GET_LEGAL_STATUS: {
      const {
        AllowEditing, TripItemId, ContractorId,
      } = action.payload;

      const id = ContractorId || 0;

      return {
        ...state,
        legalAllowEditingValues: [...state.legalAllowEditingValues, { AllowEditing, TripItemId }],
        legalContractorIds: [...state.legalContractorIds, { Id: id, TripItemId }],
      };
    }

    case ACTIONS.TRIP.SET_ERROR_LEGAL_ENTITY: {
      return {
        ...state,
        legalEntity: {
          ...state.legalEntity,
          error: true,
        },
      };
    }

    case ACTIONS.TRIP.SET_LEGAL_DIALOG: {
      return {
        ...state,
        isLegalDialog: action.payload,
        legalEntity: {
          ...state.legalEntity,
          error: false,
        },
      };
    }

    case ACTIONS.TRIP.SET_LEGAL_VALUE: {
      const { value, tripItemId } = action.payload;

      if (value.length) {
        const selectedValue = value.find(({ Id: valueId }) => {
          return state.legalContractorIds.find(({ Id, TripItemId }) => tripItemId === TripItemId && valueId === Id);
        });

        return {
          ...state,
          legalSelectValue: {
            value: selectedValue ? selectedValue.Name : '',
            id: selectedValue ? selectedValue.Id : 0,
          },
        };
      }

      return {
        ...state,
        legalSelectValue: {
          value: value.value,
          id: value.id,
        },
        legalContractorIds: state.legalContractorIds.map((contractor) => (contractor.TripItemId === tripItemId
          ? { ...contractor, Id: value.id }
          : contractor)),
      };
    }

    case ACTIONS.TRIP.LOADSUMAIRLINE: {
      const { airRefundSum } = action;

      return {
        ...state,
        airRefundSum: {
          totalRefundSum: airRefundSum.TotalRefundSum,
          totalPenaltySum: airRefundSum.TotalPenaltySum,
          success: airRefundSum.Success,
          error: airRefundSum.Error,
        },
        penalties: airRefundSum.Tickets.map((ticket) => ({
          tripItemId: ticket.TripItemId,
          basePenalty: ticket.BasePenalty,
          fee: ticket.Fee,
          commission: ticket.Commission,
        })),
        loading: false,
      };
    }

    case ACTIONS.TRIP.SETSUMAIRLINE: {
      const { setAirRefundSum } = action;

      return {
        ...state,
        successRefund: setAirRefundSum.Success,
        errorRefund: setAirRefundSum.Error,
      };
    }

    case ACTIONS.TRIP.LOADTICKETSTATUS: {
      const { ticketStatus, error } = action;

      return {
        ...state,
        ticketStatus: ticketStatus.Status,
        error,
      };
    }

    case ACTIONS.TRIP.UPDATESTATETICKET: {
      return {
        ...state,
        ticketStatus: action.ticketStatus,
      };
    }

    case ACTIONS.TRIP.UPDATETICKETSTATUS: {
      return {
        ...state,
        ticketStatus: action.ticketStatus,
      };
    }

    case ACTIONS.TRIP.LOADSUMAEROEXPRESS: {
      const { aeroExpressRefundSum } = action;

      return {
        ...state,
        aeroExpressRefundSum: {
          refundSum: aeroExpressRefundSum.RefundSum,
          result: aeroExpressRefundSum.Result,
          error: aeroExpressRefundSum.Error,
        },
        loading: false,
      };
    }

    case ACTIONS.TRIP.LOADSUMAIRLINEFAIL: {
      return {
        ...state,
        airRefundSum: {
          ...state.airRefundSum,
          success: action.success,
        },
        loading: false,
      };
    }

    case ACTIONS.TRIP.SETSUMAIRLINEFAIL: {
      return {
        ...state,
        errorRefund: action.errorRefund,
      };
    }

    case ACTIONS.TRIP.SETAEROEXPRESSFAIL: {
      const { aeroExpressFail } = action;

      return {
        ...state,
        aeroExpressFail,
      };
    }

    case ACTIONS.TRIP.TICKETSTATUSEFAIL: {
      return {
        ...state,
        error: action.error,
      };
    }

    case ACTIONS.TRIP.ADD_VOUCHERS: {
      return {
        ...state,
        customFile: action.vouchers,
      };
    }

    case ACTIONS.TRIP.ADD_BUS_VOUCHERS: {
      return {
        ...state,
        busFile: action.vouchers,
      };
    }

    case ACTIONS.TRIP.ADD_CUSTOM_VOUCHER: {
      return {
        ...state,
        customFile: [
          ...state.customFile,
          {
            uploaded: false,
            filed: false,
            file: null,
          },
        ],
      };
    }

    case ACTIONS.TRIP.ADD_EVENT_VOUCHER: {
      return {
        ...state,
        eventFile: [
          ...state.eventFile,
          {
            uploaded: false,
            filed: false,
            file: null,
          },
        ],
      };
    }

    case ACTIONS.TRIP.ADD_BUS_VOUCHER: {
      return {
        ...state,
        busFile: [
          ...state.busFile,
          {
            uploaded: false,
            filed: false,
            file: null,
          },
        ],
      };
    }

    case ACTIONS.TRIP.UPLOAD_CUSTOM_VOUCHER: {
      const { file, ind } = action.payload;

      const newData = {
        filed: false,
        uploaded: true,
        file,
      };

      return {
        ...state,
        customFile: prepareNewItemsData(state.customFile, newData, ind),
      };
    }

    case ACTIONS.TRIP.UPLOAD_EVENT_VOUCHER: {
      const { file, ind } = action.payload;

      const newData = {
        filed: false,
        uploaded: true,
        file,
      };

      return {
        ...state,
        eventFile: prepareNewItemsData(state.eventFile, newData, ind),
      };
    }

    case ACTIONS.TRIP.UPLOAD_BUS_VOUCHER: {
      const { file, ind } = action.payload;

      const newData = {
        filed: false,
        uploaded: true,
        file,
      };

      return {
        ...state,
        busFile: prepareNewItemsData(state.busFile, newData, ind),
      };
    }

    case ACTIONS.TRIP.FILED_CUSTOM_VOUCHER: {
      const lastFileInd = state.customFile.length - 1;
      const newData = {
        ...state.customFile[lastFileInd],
        filed: true,
      };

      return {
        ...state,
        customFile: prepareNewItemsData(state.customFile, newData, lastFileInd),
      };
    }

    case ACTIONS.TRIP.FILED_EVENT_VOUCHER: {
      const lastFileInd = state.eventFile.length - 1;
      const newData = {
        ...state.eventFile[lastFileInd],
        filed: true,
      };

      return {
        ...state,
        eventFile: prepareNewItemsData(state.eventFile, newData, lastFileInd),
      };
    }

    case ACTIONS.TRIP.FILED_BUS_VOUCHER: {
      const lastFileInd = state.busFile.length - 1;
      const newData = {
        ...state.busFile[lastFileInd],
        filed: true,
      };

      return {
        ...state,
        busFile: prepareNewItemsData(state.busFile, newData, lastFileInd),
      };
    }

    case ACTIONS.TRIP.RESET_CUSTOM_VOUCHER: {
      const { ind } = action.payload;

      const newData = {
        uploaded: false,
        filed: false,
        guid: '',
        file: null,
      };

      return {
        ...state,
        customFile: prepareNewItemsData(state.customFile, newData, ind),
      };
    }

    case ACTIONS.TRIP.RESET_EVENT_VOUCHER: {
      const { ind } = action.payload;

      const newData = {
        uploaded: false,
        filed: false,
        guid: '',
        file: null,
      };

      return {
        ...state,
        eventFile: prepareNewItemsData(state.eventFile, newData, ind),
      };
    }

    case ACTIONS.TRIP.RESET_BUS_VOUCHER: {
      const { ind } = action.payload;

      const newData = {
        uploaded: false,
        filed: false,
        guid: '',
        file: null,
      };

      return {
        ...state,
        busFile: prepareNewItemsData(state.busFile, newData, ind),
      };
    }

    case ACTIONS.TRIP.DEFAULT_CUSTOM_FILE: {
      return {
        ...state,
        customFile: defaultCustomFile,
      };
    }

    case ACTIONS.TRIP.DEFAULT_EVENT_FILE: {
      return {
        ...state,
        eventFile: defaultEventFile,
      };
    }

    case ACTIONS.TRIP.DEFAULT_BUS_FILE: {
      return {
        ...state,
        busFile: defaultBusFile,
      };
    }

    case ACTIONS.TRIP.SET_REFUND_DIALOG: {
      return {
        ...state,
        cancelVoucherDialog: {
          ...state.cancelVoucherDialog,
          isRefundDialog: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.SET_GIFT_VOUCHER_DIALOG: {
      return {
        ...state,
        giftVoucherDialog: {
          ...DEFAULT_GIFT_VOUCHER,
          isOpenGiftVoucher: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.SET_GIFT_VOUCHER_COMPANY_ID: {
      return {
        ...state,
        giftVoucherDialog: {
          ...state.giftVoucherDialog,
          companyId: action.payload,
        },
        errorEmployeesList: '',
      };
    }

    case ACTIONS.TRIP.SET_GIFT_ERROR_GET_EMPLOYEES_LIST: {
      return {
        ...state,
        errorEmployeesList: action.payload,
      };
    }

    case ACTIONS.TRIP.SET_GIFT_VOUCHER_EMPLOYEE: {
      const { employee, employeeInfo } = action.payload;

      return {
        ...state,
        giftVoucherDialog: {
          ...state.giftVoucherDialog,
          selectedEmployee: employee,
          employeeInfo,
        },
      };
    }

    case ACTIONS.TRIP.SET_GIFT_VOUCHER_VALUE: {
      const { field, value } = action.payload;

      return {
        ...state,
        giftVoucherDialog: {
          ...state.giftVoucherDialog,
          [field]: value,
        },
      };
    }

    case ACTIONS.TRIP.SET_VALIDATION_GIFT_VOUCHER: {
      const { field, res } = action.payload;

      return {
        ...state,
        giftVoucherDialog: {
          ...state.giftVoucherDialog,
          validation: {
            ...state.giftVoucherDialog.validation,
            [field]: res,
          },
        },
      };
    }

    case ACTIONS.TRIP.SET_GIFT_VOUCHER_RESULT: {
      return {
        ...state,
        giftVoucherDialog: {
          ...state.giftVoucherDialog,
          resultSave: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.SET_APROOVE_DIALOG: {
      const { isOpen, amount } = action.payload;

      return {
        ...state,
        cancelVoucherDialog: {
          ...state.cancelVoucherDialog,
          isAprooveDialog: isOpen,
          amountCancelVoucher: amount,
        },
      };
    }

    case ACTIONS.TRIP.SET_RESULT_DIALOG: {
      const { msg, isOpen } = action.payload;

      return {
        ...state,
        cancelVoucherDialog: {
          ...state.cancelVoucherDialog,
          isResultDialog: isOpen,
          dialogResultMsg: msg,
          cancelVoucherInputValue: '',
        },
      };
    }

    case ACTIONS.TRIP.CHANGE_CANCEL_VOUCHER_INPUT: {
      return {
        ...state,
        cancelVoucherDialog: {
          ...state.cancelVoucherDialog,
          cancelVoucherInputValue: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.SET_LOADING_DIALOG: {
      return {
        ...state,
        cancelVoucherDialog: {
          ...state.cancelVoucherDialog,
          loadingCancelDialog: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.FULL_EVENTS: {
      return {
        ...state,
        fullEvents: action.payload,
      };
    }

    case ACTIONS.TRIP.CANCEL_EVENT: {
      return {
        ...state,
        fullEvents: cancelApplication(state, action.payload),
      };
    }

    case ACTIONS.TRIP.DELETE_EVENT: {
      return {
        ...state,
        fullEvents: deleteApplication(state, action.payload),
      };
    }

    case ACTIONS.TRIP.SORTING_EVENTS: {
      return {
        ...state,
        fullEvents: sotringApplication(state, action.payload),
      };
    }

    case ACTIONS.TRIP.EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }

    case ACTIONS.TRIP.EVENT: {
      return {
        ...state,
        event: action.payload,
      };
    }

    case ACTIONS.TRIP.GET_ESTIMATE: {
      return {
        ...state,
        estimate: action.payload.Estimate,
      };
    }

    case ACTIONS.TRIP.SET_ESTIMATE: {
      return {
        ...state,
        estimate: action.payload,
      };
    }

    case ACTIONS.TRIP.ERROR_ESTIMATE: {
      return {
        ...state,
        estimate: 0,
      };
    }

    case ACTIONS.TRIP.lOADUSER:
      state.users = action.users;
      break;

    case ACTIONS.TRIP.REFRESHTRIP:
      state.currentTrip = action.trip;
      state.currentTrip.StatusRu = STATUS_RU[state.currentTrip.Status];
      state.versionButtons = preparedVersionButtons(state.currentTrip);
      break;

    case ACTIONS.TRIP.LOADCHANGECOMMISSIONAIR:
      state.changeRate.airline = action.payload;
      break;

    case ACTIONS.TRIP.LOADCHANGECOMMISSIONTRAIN:
      state.changeRate.train = action.payload;
      break;

    case ACTIONS.TRIP.LOAD_CHANGE_COMMISSION_BUS:
      state.changeRate.bus = action.payload;
      break;

    case ACTIONS.TRIP.LOADINTEGRATION:
      state.integration = action.payload;
      break;

    case ACTIONS.TRIP.LOADINTEGRATIONVAERSIONS:
      state.integrationVersions = action.payload;
      break;

    case ACTIONS.TRIP.LOADMANDATORYROJECT:
      state.mandatoryProject = action.mandatoryProject;
      break;

    case ACTIONS.TRIP.UPDATEVERSIONBUTTONS:
      state.versionButtons = state.versionButtons.map((item) => {
        const { id: itemId, fullPrice } = item;

        if (itemId === action.payload) {
          return {
            ...item,
            fullPrice: !fullPrice,
          };
        }

        return item;
      });
      break;

    case ACTIONS.TRIP.CLEARSTORE:
      return DEFAULTTRIP;

    case ACTIONS.TRIP.AUTOCOMPLETE_EMPLOYEES: {
      const { payload: { employeesList } } = action;

      return {
        ...state,
        employeesList,
      };
    }

    case ACTIONS.TRIP.LOADING_AUTOCOMPLETE_EMPLOYEES: {
      return {
        ...state,
        loadingEmployeesList: action.payload,
      };
    }

    case ACTIONS.TRIP.SETANALYTICS:
      return {
        ...prevState,
        currentTrip: {
          ...prevState.currentTrip,
          UserAnalytics: action.payload,
        },
      };

    case ACTIONS.TRIP.UPDATE_TRIP_COMMENTS: {
      return {
        ...state,
        tripComments: action.payload,
      };
    }

    case ACTIONS.TRIP.SAVE_OPERATION_AIRLINE: {
      return {
        ...state,
        operationAirline: action.payload,
      };
    }

    case ACTIONS.TRIP.SAVE_TRIP_VERSIONS_COUNT: {
      return {
        ...state,
        versionsCount: action.payload,
      };
    }

    case ACTIONS.TRIP.ITEMS_INSURANCE: {
      const { items } = action.payload;

      return {
        ...state,
        insurance: {
          ...state.insurance,
          items,
        },
      };
    }

    case ACTIONS.TRIP.SET_RATES: {
      const { insurance } = state;
      const { selectedRate } = insurance;
      const { rates } = action.payload;

      const sortedRates = rates.sort(({ Price: PriceFirst }, { Price: PriceSecond }) => PriceFirst - PriceSecond);

      if (selectedRate) {
        const newPrice = rates.find(({ Rate }) => Rate === selectedRate).Price / 2;

        return {
          ...state,
          insurance: {
            ...state.insurance,
            rates: sortedRates,
            price: {
              Base: newPrice,
              Commission: newPrice,
            },
          },
        };
      }

      return {
        ...state,
        insurance: {
          ...state.insurance,
          rates: sortedRates,
        },
      };
    }

    case ACTIONS.TRIP.SET_PROVIDER_INSURANCE: {
      return {
        ...state,
        insurance: {
          ...state.insurance,
          provider: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.SELECT_ITEM_INSURANCE: {
      const { service } = action.payload;
      const { TripItemId, Amount } = service;

      const isSelectedItem = state.insurance.selectedItems.find(({ TripItemId: id }) => id === TripItemId);
      const newSelectedItems = !isSelectedItem
        ? [...state.insurance.selectedItems, service]
        : state.insurance.selectedItems.filter(({ TripItemId: id }) => id !== TripItemId);
      const totalPrice = !isSelectedItem
        ? state.insurance.totalPrice + Amount
        : state.insurance.totalPrice - Amount;

      return {
        ...state,
        insurance: {
          ...state.insurance,
          selectedItems: newSelectedItems,
          totalPrice,
        },
      };
    }

    case ACTIONS.TRIP.SELECT_ALL_ITEMS_INSURANCE: {
      return {
        ...state,
        insurance: {
          ...state.insurance,
          selectedItems: state.insurance.items,
          totalPrice: state.insurance.items.reduce((pre, { Amount }) => (pre + Amount), 0),
        },
      };
    }

    case ACTIONS.TRIP.DELETE_ALL_SELECTED_ITEMS_INSURANCE: {
      return {
        ...state,
        insurance: {
          ...state.insurance,
          selectedItems: [],
          totalPrice: 0,
        },
      };
    }

    case ACTIONS.TRIP.SELECT_RATE_INSURANCE: {
      const { name, price } = action.payload;

      const newPrice = price / 2;

      return {
        ...state,
        insurance: {
          ...state.insurance,
          selectedRate: name,
          price: {
            Base: newPrice,
            Commission: newPrice,
          },
        },
      };
    }

    case ACTIONS.TRIP.CHANGE_PRICE_INSURANCE: {
      const { field, value } = action.payload;

      return {
        ...state,
        insurance: {
          ...state.insurance,
          price: {
            ...state.insurance.price,
            [field]: value,
          },
        },
      };
    }

    case ACTIONS.TRIP.SET_VALIDATION_INSURANCE: {
      const {
        field, res, idx,
      } = action.payload;
      const { insurance } = state;
      const { validation } = insurance;

      if (
        field === FIELDS.NAME
        || field === FIELDS.DIRECTION
        || field === FIELDS.CHECKIN_DATE
        || field === FIELDS.CHECKOUT_DATE
        || field === FIELDS.AMOUNT
      ) {
        const newItemsValidation = validation.Items.map((item, index) => {
          if (idx === index) {
            return {
              ...item,
              [field]: res,
            };
          }

          return item;
        });

        return {
          ...state,
          insurance: {
            ...insurance,
            validation: {
              ...validation,
              Items: newItemsValidation,
            },
          },
        };
      }

      return {
        ...state,
        insurance: {
          ...insurance,
          validation: {
            ...validation,
            [field]: res,
          },
        },
      };
    }

    case ACTIONS.TRIP.SELECT_COMPANY_ID: {
      return {
        ...state,
        insurance: {
          ...state.insurance,
          companyId: action.payload.id,
        },
      };
    }

    case ACTIONS.TRIP.SET_EDITED_INSURANCE: {
      const {
        CompanyId,
        JsonData,
        ProviderName,
        ProviderInn,
        OrderTripItemId,
        Employees,
      } = action.payload;

      const data = JSON.parse(JsonData);
      const {
        Rate,
        InsuranceServices,
        PriceDetails: { Base, Commission },
        OrderId,
      } = data;
      const totalPrice = InsuranceServices.reduce((pre, { Amount }) => pre + Amount, 0);

      return {
        ...state,
        insurance: {
          ...state.insurance,
          companyId: CompanyId,
          provider: ProviderName,
          selectedRate: Rate,
          selectedItems: InsuranceServices,
          orderTripItemId: OrderTripItemId,
          orderId: OrderId,
          employees: Employees,
          status: 1,
          items: InsuranceServices,
          ProviderINN: ProviderInn,
          price: {
            ...state.insurance.price,
            Base,
            Commission,
          },
          totalPrice,
          validation: {
            ...state.insurance.validation,
            Items: InsuranceServices.map(() => ({
              Direction: '',
              Name: '',
              CheckinDate: '',
              CheckoutDate: '',
            })),
          },
        },
      };
    }

    case ACTIONS.TRIP.SET_DESCRIPTION_INSURANCE: {
      const { description } = action.payload;

      return {
        ...state,
        insurance: {
          ...state.insurance,
          description,
        },
      };
    }

    case ACTIONS.TRIP.CHANGE_DATA_SERVICE_INSURANCE: {
      const {
        field, value, idx,
      } = action.payload;
      const { insurance } = state;

      const newItems = insurance.items.map((item, index) => {
        if (idx === index) {
          const { Data } = item;
          const parsedData = JSON.parse(Data);

          const newData = {
            ...parsedData,
            [field]: value,
          };

          return {
            ...item,
            Data: JSON.stringify(newData),
          };
        }

        return item;
      });

      return {
        ...state,
        insurance: {
          ...insurance,
          items: newItems,
        },
      };
    }

    case ACTIONS.TRIP.CHANGE_EDITED_SERVICE_INSURANCE: {
      const {
        field, value, idx,
      } = action.payload;
      const { insurance } = state;

      const newItems = insurance.items.map((item, index) => {
        if (idx === index) {
          return {
            ...item,
            [field]: value,
          };
        }

        return item;
      });
      const newTotalPrice = newItems.reduce((pre, { Amount }) => (pre + Number(Amount)), 0);

      return {
        ...state,
        insurance: {
          ...insurance,
          items: newItems,
          totalPrice: newTotalPrice,
        },
      };
    }

    case ACTIONS.TRIP.RESET_INSURANCE: {
      return {
        ...state,
        insurance: {
          rates: [],
          items: [],
          companyId: 0,
          provider: PROVIDERS_NAMES.VSK,
          selectedItems: [],
          selectedRate: '',
          description: '',
          totalPrice: 0,
          price: {
            Base: 0,
            Commission: 0,
          },
          validation: {
            Items: '',
            Rate: '',
            Base: '',
            Commission: '',
            CompanyId: '',
            Description: '',
          },
        },
      };
    }

    case ACTIONS.TRIP.CHANGE_FIELD_GROUP_BOOKING: {
      const { field, value } = action.payload;

      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          [field]: value,
        },
      };
    }

    case ACTIONS.TRIP.SET_VALIDATION_GROUP_HOTEL: {
      const { field, value } = action.payload;
      const part = field.split('.');

      if (part.length === 1) {
        return {
          ...state,
          groupHotels: {
            ...state.groupHotels,
            validation: {
              ...state.groupHotels.validation,
              [field]: value,
            },
          },
        };
      }

      if (part.length === 2) {
        return {
          ...state,
          groupHotels: {
            ...state.groupHotels,
            validation: {
              ...state.groupHotels.validation,
              [part[0]]: {
                ...state.groupHotels.validation[part[0]],
                [part[1]]: value,
              },
            },
          },
        };
      }
      break;
    }

    case ACTIONS.TRIP.RESET_HOTEL_PROVIDER_DATA: {
      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          ProviderInn: '',
          ReportAmount: 0,
        },
      };
    }

    case ACTIONS.TRIP.RESET_MAIN_HOTEL_FIELDS: {
      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          hotelName: {
            label: '',
            selected: null,
            suggests: [],
          },
          CustomHotelName: '',
          contract: null,
          connected: null,
          compared: null,
          showModal: false,
          showModalEditMainHotelFields: true,
          disableHotelAutocomplete: true,
          disableMainHotelFields: false,
          JsonData: {
            ...state.groupHotels.JsonData,
            ReservationDate: null,
            Hotel: {
              Name: '',
              Address: '',
              Email: '',
              City: '',
              Phone: '',
              RegionId: 0,
              Stars: 0,
              CheckinTime: '',
              CheckoutTime: '',
              ClassificatorId: 0,
              Location: '',
            },
          },
        },
      };
    }

    case ACTIONS.TRIP.CHANGE_JSON_INPUT_GROUP_HOTEL: {
      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          JsonData: action.payload,
        },
      };
    }

    case ACTIONS.TRIP.SET_GROUP_HOTEL_NAME: {
      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          hotelName: {
            ...state.groupHotels.hotelName,
            label: action.payload,
          },
          JsonData: {
            ...state.groupHotels.JsonData,
            Hotel: {
              ...state.groupHotels.JsonData.Hotel,
              Name: action.payload,
              ClassificatorId: null,
            },
          },
        },
      };
    }

    case ACTIONS.TRIP.SET_GROUP_HOTEL_NAME_SUGGEST: {
      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          hotelName: {
            ...state.groupHotels.hotelName,
            suggests: action.payload,
          },
        },
      };
    }

    case ACTIONS.TRIP.SET_GROUP_BOOKING_HOTEL_DETAILS: {
      const {
        Name,
        Address,
        City,
        Phone,
        Email,
        Stars,
        CheckinTime,
        CheckoutTime,
        ClassificatorId,
        CountryCode,
        RegionId,
      } = action.payload;

      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          hotelName: {
            ...state.groupHotels.hotelName,
            label: Name,
          },
          CustomHotelName: Name,
          CountryCode,
          JsonData: {
            ...state.groupHotels.JsonData,
            Hotel: {
              ...state.groupHotels.JsonData.Hotel,
              Name,
              Address,
              City,
              Phone,
              RegionId,
              Email: Email || '',
              Stars,
              CheckinTime,
              CheckoutTime,
              ClassificatorId: ClassificatorId || null,
            },
          },
        },
      };
    }

    case ACTIONS.TRIP.SET_CONTRACT_CONNECT_COMPARE_GROUP_BOOKING: {
      const {
        contract, connected, compared,
      } = action.payload;

      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          contract: contract || null,
          connected: connected.length ? connected[0] : null,
          compared: compared || null,
        },
      };
    }

    case ACTIONS.TRIP.SET_RATES_GROUP_BOOKING: {
      return {
        ...state,
        groupHotels: {
          ...state.groupHotels,
          rates: action.payload,
          ratePlaneId: action.payload ? action.payload[0].id : 0,
        },
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Trip', reducer, DEFAULTTRIP);

  const createStore = () => new Store('Trip', reducer, DEFAULTTRIP);

  return createStore();
}
